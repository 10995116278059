import Swiper from 'swiper';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';

const gallerySliderClass = '.js-gallery-slider';

const sliderGallery = new Swiper(gallerySliderClass, {
    modules: [Autoplay, Navigation, Pagination],
    loop: false,
    speed: 650,
    spaceBetween: 48,
    loopAddBlankSlides: true,
    loopPreventsSliding: true,
    navigation: {
        nextEl: '.gallery-slider--next',
        prevEl: '.gallery-slider--prev',
    },
    pagination: {
        el: '.gallery-slider-pagination',
        type: 'fraction',
    },
    breakpoints: {
        500: {
            slidesPerView: 1,
            spaceBetween: 20,
            slidesPerGroup: 1
        },
        991: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 40,
        },
        1200: {
            slidesPerView: 3,
            slidesPerGroup: 3
        },
    },
});
